import { Link } from 'react-router-dom';

export const EnglishIntro = () => {
  return (
    <div className='py-6 px-8 lg:py-20 lg:px-12 lg:pl-36 lg:pr-12'>
      <div>
        <div className='text-xl lg:text-4xl font-extrabold font-nanum keep-all'>
          영어 학습을 e-Book, 학습 영상, VR 체험 등 다양한 방식으로 몰입감 있고 실재감 있게 학습과 체험을 경험하세요!
        </div>
        <div className='flex flex-wrap md:flex-nowrap content-between justify-start items-center py-2 lg:py-10 lg:space-x-6'>
          <div className='w-1/2 p-2'>
            <img src={'/images/village/english/book.png'} alt='book' />
          </div>
          <div className='w-1/2 p-2'>
            <img src={'/images/village/english/ebook.png'} alt='ebook' />
          </div>
          <div className='w-1/2 p-2'>
            <img src={'/images/village/english/video.png'} alt='video' />
          </div>
          <div className='w-1/2 p-2'>
            <img src={'/images/village/english/vr.png'} alt='vr' />
          </div>
        </div>
      </div>
      <div className='py-2 lg:py-6'>
        <div className='flex flex-col md:flex-row justify-start items-center lg:space-x-12'>
          <div className='cursor-pointer py-2 md:px-2'>
            <Link to='/village/english/contents/storybook'>
              <img src={'/images/village/english/storybook_btn.png'} alt='storybook_btn' />
            </Link>
          </div>
          <div className='cursor-pointer py-2 md:px-2'>
            <Link to='/village/english/contents/story-readers'>
              <img src={'/images/village/english/story_readers_btn.png'} alt='story_readers_btn' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
