export type villageContent = {
  title: string;
  imgUrl: string;
  tag: string;
};

export type village = {
  name: string;
  villageContent: villageContent[];
  tags: string[];
  group: string;
};

const storybookContents: villageContent[] = [
  {
    title: 'Aladdin and the Magic Lamp',
    imgUrl: '/images/village/english/contents/storybook/1. Aladdin and the Magic Lamp.png',
    tag: 'spring',
  },
  {
    title: 'Alice in Wonderland',
    imgUrl: '/images/village/english/contents/storybook/2. Alice in Wonderland.png',
    tag: 'spring',
  },
  {
    title: 'Beauty and the Beast',
    imgUrl: '/images/village/english/contents/storybook/3. Beauty and the Beast.png',
    tag: 'spring',
  },
  { title: 'Cinderella', imgUrl: '/images/village/english/contents/storybook/4. Cinderella.png', tag: 'spring' },
  {
    title: 'Goldilocks and the Three Bears',
    imgUrl: '/images/village/english/contents/storybook/5. Goldilocks and the Three Bears.png',
    tag: 'spring',
  },
  {
    title: 'Little Red Riding Hood',
    imgUrl: '/images/village/english/contents/storybook/6. Little Red Riding Hood.png',
    tag: 'spring',
  },
  {
    title: 'Snow White and the Seven Dwarfs',
    imgUrl: '/images/village/english/contents/storybook/7. Snow White and the Seven Dwarfs.png',
    tag: 'spring',
  },
  {
    title: 'The Adventures of Pinocchio',
    imgUrl: '/images/village/english/contents/storybook/8. The Adventures of Pinocchio.png',
    tag: 'spring',
  },
  {
    title: 'The Little Mermaid',
    imgUrl: '/images/village/english/contents/storybook/9. The Little Mermaid.png',
    tag: 'summer',
  },
  {
    title: 'The jungle book',
    imgUrl: '/images/village/english/contents/storybook/10. The jungle book.png',
    tag: 'summer',
  },
  {
    title: 'Alibaba and the Forty Thieves',
    imgUrl: '/images/village/english/contents/storybook/11. Alibaba and the Forty Thieves.png',
    tag: 'summer',
  },
  {
    title: 'Ant and The Grasshopper',
    imgUrl: '/images/village/english/contents/storybook/12. Ant and The Grasshopper.png',
    tag: 'summer',
  },
  {
    title: 'Bambi',
    imgUrl: '/images/village/english/contents/storybook/13.Bambi.png',
    tag: 'summer',
  },
  {
    title: 'Emperor_s New clothes',
    imgUrl: '/images/village/english/contents/storybook/14.Emperor_s New clothes.png',
    tag: 'summer',
  },
  {
    title: 'Gulliver_s Travel',
    imgUrl: '/images/village/english/contents/storybook/15.Gulliver_s Travel.png',
    tag: 'summer',
  },
  {
    title: 'Hansel and Gretel',
    imgUrl: '/images/village/english/contents/storybook/16.Hansel and Gretel.png',
    tag: 'summer',
  },
  {
    title: 'Heidi',
    imgUrl: '/images/village/english/contents/storybook/17.Heidi.png',
    tag: 'fall',
  },
  {
    title: 'Jack and Beanstalk',
    imgUrl: '/images/village/english/contents/storybook/18.Jack and Beanstalk.png',
    tag: 'fall',
  },
  {
    title: 'Puss in Boots',
    imgUrl: '/images/village/english/contents/storybook/19.Puss in Boots.png',
    tag: 'fall',
  },
  {
    title: 'Rapunzel',
    imgUrl: '/images/village/english/contents/storybook/20.Rapunzel.png',
    tag: 'fall',
  },
  {
    title: 'Rumpelstiltskin',
    imgUrl: '/images/village/english/contents/storybook/21. Rumpelstiltskin.png',
    tag: 'fall',
  },
  {
    title: 'Sinbad the Sailor',
    imgUrl: '/images/village/english/contents/storybook/22. Sinbad the Sailor.png',
    tag: 'fall',
  },
  {
    title: 'Sleeping Beauty',
    imgUrl: '/images/village/english/contents/storybook/23.Sleeping Beauty.png',
    tag: 'fall',
  },
  {
    title: 'The Adventure of Peter Pan',
    imgUrl: '/images/village/english/contents/storybook/24.The Adventure of Peter Pan.png',
    tag: 'fall',
  },
  {
    title: 'The Brave Tin Soldier',
    imgUrl: '/images/village/english/contents/storybook/25.The Brave Tin Soldier.png',
    tag: 'winter',
  },
  {
    title: 'The Country Mouse and Town Mouse',
    imgUrl: '/images/village/english/contents/storybook/26.The Country Mouse and Town Mouse.png',
    tag: 'winter',
  },
  {
    title: 'The Ginjerbread Man',
    imgUrl: '/images/village/english/contents/storybook/27.The Ginjerbread Man.png',
    tag: 'winter',
  },
  {
    title: 'The Pied Piper of Hamelin',
    imgUrl: '/images/village/english/contents/storybook/28.The Pied Piper of Hamelin.png',
    tag: 'winter',
  },
  {
    title: 'The Ugly Duckling',
    imgUrl: '/images/village/english/contents/storybook/29.The Ugly Duckling.png',
    tag: 'winter',
  },
  {
    title: 'The Wizard of Oz',
    imgUrl: '/images/village/english/contents/storybook/30.The Wizard of Oz.png',
    tag: 'winter',
  },
  {
    title: 'Three Little Pigs',
    imgUrl: '/images/village/english/contents/storybook/31.Three Little Pigs.png',
    tag: 'winter',
  },
  {
    title: 'Thumbelina',
    imgUrl: '/images/village/english/contents/storybook/32.Thumbelina.png',
    tag: 'winter',
  },
];

const storyReadersContents: villageContent[] = [
  {
    title: 'yes, i can',
    imgUrl: '/images/village/english/contents/story_readers/lv1/yes  can.png',
    tag: 'lv1',
  },
  {
    title: 'How to get along with your friends',
    imgUrl: '/images/village/english/contents/story_readers/lv1/How to get along with your friends.png',
    tag: 'lv1',
  },
  {
    title: 'Playing with shapes',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Playing with shapes.png',
    tag: 'lv1',
  },
  {
    title: 'Guess Who',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Guess Who.png',
    tag: 'lv1',
  },
  {
    title: 'My Delicious dinner',
    imgUrl: '/images/village/english/contents/story_readers/lv1/My Delicious dinner.png',
    tag: 'lv1',
  },
  {
    title: 'Under the sea',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Under the sea.png',
    tag: 'lv1',
  },
  {
    title: 'The Four Seasons',
    imgUrl: '/images/village/english/contents/story_readers/lv1/1.The Four Seasons.png',
    tag: 'lv1',
  },
  {
    title: 'It is night time',
    imgUrl: '/images/village/english/contents/story_readers/lv1/2.It is night time.png',
    tag: 'lv1',
  },
  {
    title: 'The counting game',
    imgUrl: '/images/village/english/contents/story_readers/lv1/3.The counting game.png',
    tag: 'lv1',
  },
  {
    title: 'Happy Zoo',
    imgUrl: '/images/village/english/contents/story_readers/lv1/4.Happy Zoo.png',
    tag: 'lv1',
  },
  {
    title: 'At the Farm',
    imgUrl: '/images/village/english/contents/story_readers/lv1/5.At the Farm.png',
    tag: 'lv1',
  },
  {
    title: '1,2,3!',
    imgUrl: '/images/village/english/contents/story_readers/lv1/6.1,2,3!.png',
    tag: 'lv1',
  },
  {
    title: 'Welcome to the Savannah!',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Welcome to the Savannah!.png',
    tag: 'lv1',
  },
  {
    title: 'My friends',
    imgUrl: '/images/village/english/contents/story_readers/lv1/My friends.png',
    tag: 'lv1',
  },
  {
    title: 'I like Tomatoes',
    imgUrl: '/images/village/english/contents/story_readers/lv1/I like Tomatoes.png',
    tag: 'lv1',
  },
  {
    title: 'We can move!',
    imgUrl: '/images/village/english/contents/story_readers/lv1/We can move!.png',
    tag: 'lv1',
  },
  {
    title: 'Where are you from',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Where are you from.png',
    tag: 'lv1',
  },
  {
    title: 'Light or Heavy',
    imgUrl: '/images/village/english/contents/story_readers/lv1/Light or Heavy.png',
    tag: 'lv1',
  },
  {
    title: 'Where can it be',
    imgUrl: '/images/village/english/contents/story_readers/lv1/1.Where can it be.png',
    tag: 'lv1',
  },
  {
    title: 'The clock',
    imgUrl: '/images/village/english/contents/story_readers/lv1/2.The clock.png',
    tag: 'lv1',
  },
  {
    title: 'Daytime and Nighttime',
    imgUrl: '/images/village/english/contents/story_readers/lv1/3.Daytime and Nighttime.png',
    tag: 'lv1',
  },
  {
    title: 'I love it, I hate it',
    imgUrl: '/images/village/english/contents/story_readers/lv1/4.I love it, I hate it.png',
    tag: 'lv1',
  },
  {
    title: 'How is the weather',
    imgUrl: '/images/village/english/contents/story_readers/lv1/5.How is the weather.png',
    tag: 'lv1',
  },
  {
    title: 'How do you travel',
    imgUrl: '/images/village/english/contents/story_readers/lv1/6.How do you travel.png',
    tag: 'lv1',
  },
  {
    title: 'The Rainbow',
    imgUrl: '/images/village/english/contents/story_readers/lv2/1.The Rainbow.png',
    tag: 'lv2',
  },
  {
    title: 'Where is it',
    imgUrl: '/images/village/english/contents/story_readers/lv2/2.Where is it.png',
    tag: 'lv2',
  },
  {
    title: `Let's Play Ball!`,
    imgUrl: `/images/village/english/contents/story_readers/lv2/3.Let's Play Ball!.png`,
    tag: 'lv2',
  },
  {
    title: `Let's Make a Sandwich!`,
    imgUrl: `/images/village/english/contents/story_readers/lv2/4.Let's Make a Sandwich!.png`,
    tag: 'lv2',
  },
  {
    title: 'The Adding Game',
    imgUrl: '/images/village/english/contents/story_readers/lv2/5.The Adding Game.png',
    tag: 'lv2',
  },
  {
    title: 'Thomas the Little Car',
    imgUrl: '/images/village/english/contents/story_readers/lv2/6.Thomas the Little Car.png',
    tag: 'lv2',
  },
  {
    title: 'The Raindrop and Friends',
    imgUrl: '/images/village/english/contents/story_readers/lv2/1.The Raindrop and Friends.png',
    tag: 'lv2',
  },
  {
    title: 'You Can Do it!',
    imgUrl: '/images/village/english/contents/story_readers/lv2/2.You Can Do it!.png',
    tag: 'lv2',
  },
  {
    title: 'The Weather Family',
    imgUrl: '/images/village/english/contents/story_readers/lv2/3.The Weather Family.png',
    tag: 'lv2',
  },
  {
    title: 'Blinky the Traffic Light',
    imgUrl: '/images/village/english/contents/story_readers/lv2/4.Blinky the Traffic Light.png',
    tag: 'lv2',
  },
  {
    title: `Don't be lazy!`,
    imgUrl: `/images/village/english/contents/story_readers/lv2/5.Don't be lazy!.png`,
    tag: 'lv2',
  },
  {
    title: `How to be a good child`,
    imgUrl: '/images/village/english/contents/story_readers/lv2/6.How to be a good child.png',
    tag: 'lv2',
  },
  {
    title: 'Cats and Dogs',
    imgUrl: '/images/village/english/contents/story_readers/lv2/1.Cats and Dogs.png',
    tag: 'lv2',
  },
  {
    title: 'What is your hobby',
    imgUrl: '/images/village/english/contents/story_readers/lv2/2.What is your hobby.png',
    tag: 'lv2',
  },
  {
    title: 'I love Ice Cream!',
    imgUrl: '/images/village/english/contents/story_readers/lv2/3.I love Ice Cream!.png',
    tag: 'lv2',
  },
  {
    title: 'The Park',
    imgUrl: '/images/village/english/contents/story_readers/lv2/4.The Park.png',
    tag: 'lv2',
  },
  {
    title: 'Keep your room clean',
    imgUrl: '/images/village/english/contents/story_readers/lv2/5.Keep your room clean.png',
    tag: 'lv2',
  },
  {
    title: 'Inside the Shopping Bag',
    imgUrl: '/images/village/english/contents/story_readers/lv2/6.Inside the Shopping Bag.png',
    tag: 'lv2',
  },
  {
    title: 'The plant is growing',
    imgUrl: '/images/village/english/contents/story_readers/lv2/1.The plant is growing.png',
    tag: 'lv2',
  },
  {
    title: 'Dots,Lines,and Shapes',
    imgUrl: '/images/village/english/contents/story_readers/lv2/2.Dots,Lines,and Shapes.png',
    tag: 'lv2',
  },
  {
    title: `Don't be greedy!`,
    imgUrl: `/images/village/english/contents/story_readers/lv2/3.Don't be greedy!.png`,
    tag: 'lv2',
  },
  {
    title: 'Hot and cold',
    imgUrl: '/images/village/english/contents/story_readers/lv2/4.Hot and cold.png',
    tag: 'lv2',
  },
  {
    title: 'Happy Birthday',
    imgUrl: '/images/village/english/contents/story_readers/lv2/5.Happy Birthday.png',
    tag: 'lv2',
  },
  {
    title: 'Wrapping Gifts',
    imgUrl: '/images/village/english/contents/story_readers/lv2/6.Wrapping Gifts.png',
    tag: 'lv2',
  },
  {
    title: `The indian's Sack`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/1.The indian's Sack.png`,
    tag: 'lv3',
  },
  {
    title: `The fairy of good fortune`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/2.The fairy of good fortune.png`,
    tag: 'lv3',
  },
  {
    title: `The hard-working snail`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/3.The hard-working snail.png`,
    tag: 'lv3',
  },
  {
    title: `Day and night`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/4.Day and night.png`,
    tag: 'lv3',
  },
  {
    title: `How to avoid the dentist`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/5.How to avoid the dentist.png`,
    tag: 'lv3',
  },
  {
    title: `Snow is falling`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/6.Snow is falling.png`,
    tag: 'lv3',
  },
  {
    title: `The Race`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/1.The Race.png`,
    tag: 'lv3',
  },
  {
    title: `We are partners!`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/2.We are partners!.png`,
    tag: 'lv3',
  },
  {
    title: `George wants glasses`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/3.George wants glasses.png`,
    tag: 'lv3',
  },
  {
    title: `Before you eat`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/4.Before you eat.png`,
    tag: 'lv3',
  },
  {
    title: `Who am I`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/5.Who am I.png`,
    tag: 'lv3',
  },
  {
    title: `The best present ever`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/6.The best present ever.png`,
    tag: 'lv3',
  },
  {
    title: `The Princess of Clocks`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/1.The Princess of Clocks.png`,
    tag: 'lv3',
  },
  {
    title: `Visiting the Library`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/2.Visiting the Library.png`,
    tag: 'lv3',
  },
  {
    title: `Save the Baby Penguins!`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/3.Save the Baby Penguins!.png`,
    tag: 'lv3',
  },
  {
    title: `My New Friend`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/4.My New Friend.png`,
    tag: 'lv3',
  },
  {
    title: `What does it mean`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/5.What does it mean.png`,
    tag: 'lv3',
  },
  {
    title: `My mom is the best`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/6.My mom is the best.png`,
    tag: 'lv3',
  },
  {
    title: `Sara's Tree`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/1.Sara's Tree.png`,
    tag: 'lv3',
  },
  {
    title: `Washing your hands`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/2.Washing your hands.png`,
    tag: 'lv3',
  },
  {
    title: `Our Tongue`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/3.Our Tongue.png`,
    tag: 'lv3',
  },
  {
    title: `The story of the Seed`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/4.The story of the Seed.png`,
    tag: 'lv3',
  },
  {
    title: `Peek a boo, where are you`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/5.Peek a boo, where are you.png`,
    tag: 'lv3',
  },
  {
    title: `I have a new brother!`,
    imgUrl: `/images/village/english/contents/story_readers/lv3/6.I have a new brother!.png`,
    tag: 'lv3',
  },
  {
    title: `The Foolish cat`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/1.The Foolish cat.png`,
    tag: 'lv4',
  },
  {
    title: `Vegetable school`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/2.Vegetable school.png`,
    tag: 'lv4',
  },
  {
    title: `The Pink Dolphin Botto`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/3.The Pink Dolphin Botto.png`,
    tag: 'lv4',
  },
  {
    title: `The lunch's journey`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/4.The lunch's journey.png`,
    tag: 'lv4',
  },
  {
    title: `I am Everywhere`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/5.I am Everywhere.png`,
    tag: 'lv4',
  },
  {
    title: `Visting the Hospital`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/6.Visting the Hospital.png`,
    tag: 'lv4',
  },
  {
    title: `Danny's delightful drawing`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/1.Danny's delightful drawing.png`,
    tag: 'lv4',
  },
  {
    title: `We need the sun`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/2.We need the sun.png`,
    tag: 'lv4',
  },
  {
    title: `What will I become`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/3.What will I become.png`,
    tag: 'lv4',
  },
  {
    title: `The Orchestra Concert`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/4.The Orchestra Concert.png`,
    tag: 'lv4',
  },
  {
    title: `Let's go to the movies!`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/5.Let's go to the movies!.png`,
    tag: 'lv4',
  },
  {
    title: `I am the best`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/6.I am the best.png`,
    tag: 'lv4',
  },
  {
    title: `Let's help each other`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/1.Let's help each other.png`,
    tag: 'lv4',
  },
  {
    title: `Gathering acorns for the winter`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/2.Gathering acorns for the winter.png`,
    tag: 'lv4',
  },
  {
    title: `I can clean my room`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/3.I can clean my room.png`,
    tag: 'lv4',
  },
  {
    title: `Don't be Picky!`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/4.Don't be Picky!.png`,
    tag: 'lv4',
  },
  {
    title: `A stranger is in town`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/5.A stranger is in town.png`,
    tag: 'lv4',
  },
  {
    title: `The snail's House`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/6.The snail's House.png`,
    tag: 'lv4',
  },
  {
    title: `The Magic Moon`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/1.The Magic Moon.png`,
    tag: 'lv4',
  },
  {
    title: `Our Earth`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/2.Our Earth.png`,
    tag: 'lv4',
  },
  {
    title: `Mr.Clock goes outside`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/3.Mr.Clock goes outside.png`,
    tag: 'lv4',
  },
  {
    title: `Chichi the Baby Hedgehog`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/4.Chichi the Baby Hedgehog.png`,
    tag: 'lv4',
  },
  {
    title: `Delicious Crepes`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/5.Delicious Crepes.png`,
    tag: 'lv4',
  },
  {
    title: `The Rainbow Game`,
    imgUrl: `/images/village/english/contents/story_readers/lv4/6.The Rainbow Game.png`,
    tag: 'lv4',
  },
  {
    title: `Strange parents`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/1.Strange parents.png`,
    tag: 'lv5',
  },
  {
    title: `The raindrop's journey`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/2.The raindrop's journey.png`,
    tag: 'lv5',
  },
  {
    title: `Baking a cake with Mom`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/3.Baking a cake with Mom.png`,
    tag: 'lv5',
  },
  {
    title: `Running errands`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/4.Running errands.png`,
    tag: 'lv5',
  },
  {
    title: `Oh! My chocolate chip cookie!`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/5.Oh! My chocolate chip cookie!.png`,
    tag: 'lv5',
  },
  {
    title: `Inca Civilization`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/6.Inca Civilization.png`,
    tag: 'lv5',
  },
  {
    title: `The Olympics`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/1.The Olympics.png`,
    tag: 'lv5',
  },
  {
    title: `The Origin of Santa Claus`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/2.The Origin of Santa Claus.png`,
    tag: 'lv5',
  },
  {
    title: `The First Weather Forecast`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/3.The First Weather Forecast.png`,
    tag: 'lv5',
  },
  {
    title: `How are Flowers Made`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/4.How are Flowers Made.png`,
    tag: 'lv5',
  },
  {
    title: `The World Map`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/5.The World Map.png`,
    tag: 'lv5',
  },
  {
    title: `The Invention of the Computer`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/6.The Invention of the Computer.png`,
    tag: 'lv5',
  },
  {
    title: `Perfume`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/1.Perfume.png`,
    tag: 'lv5',
  },
  {
    title: `Kelly's Time Travel`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/2.Kelly's Time Travel.png`,
    tag: 'lv5',
  },
  {
    title: `The Salmon's Journey`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/3.The Salmon's Journey.png`,
    tag: 'lv5',
  },
  {
    title: `What Happened to the Alligators and the Salmon`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/4.What Happened to the Alligators and the Salmon.png`,
    tag: 'lv5',
  },
  {
    title: `Levi Strauss' Blue Jeans`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/5.Levi Strauss' Blue Jeans.png`,
    tag: 'lv5',
  },
  {
    title: `Many is a Gardener`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/6.Many is a Gardener.png`,
    tag: 'lv5',
  },
  {
    title: `The Sun's Friends`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/1.The Sun's Friends.png`,
    tag: 'lv5',
  },
  {
    title: `Clooney can clean!`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/2.Clooney can clean!.png`,
    tag: 'lv5',
  },
  {
    title: `Hello, Mr. Moon!`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/3.Hello, Mr. Moon!.png`,
    tag: 'lv5',
  },
  {
    title: `The Wright Brothers' Airplane`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/4.The Wright Brothers' Airplane.png`,
    tag: 'lv5',
  },
  {
    title: `The Doughnut`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/5.The Doughnut.png`,
    tag: 'lv5',
  },
  {
    title: `Satellites in Space`,
    imgUrl: `/images/village/english/contents/story_readers/lv5/6.Satellites in Space.png`,
    tag: 'lv5',
  },
  {
    title: `The 12 Zodiacs`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/1.The 12 Zodiacs.png`,
    tag: 'lv6',
  },
  {
    title: `The Nobel Prize`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/2.The Nobel Prize.png`,
    tag: 'lv6',
  },
  {
    title: `Louis the 14th, the sun king`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/3.Louis the 14th, the sun king.png`,
    tag: 'lv6',
  },
  {
    title: `The World's Eco-Ffiendly City`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/4.The World's Eco-Ffiendly City.png`,
    tag: 'lv6',
  },
  {
    title: `The Rules of the Ecosystem`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/5.The Rules of the Ecosystem.png`,
    tag: 'lv6',
  },
  {
    title: `FASCINATING PLANTS`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/6.FASCINATING PLANTS.png`,
    tag: 'lv6',
  },
  {
    title: `How is Salt Made`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/1.How is Salt Made.png`,
    tag: 'lv6',
  },
  {
    title: `How were Fossils Made`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/2.How were Fossils Made.png`,
    tag: 'lv6',
  },
  {
    title: `The tree is our friend`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/3.The tree is our friend.png`,
    tag: 'lv6',
  },
  {
    title: `The Fall of the Persian Empire`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/4.The Fall of the Persian Empire.png`,
    tag: 'lv6',
  },
  {
    title: `Diabetes and human metabolism`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/5.Diabetes and human metabolism.png`,
    tag: 'lv6',
  },
  {
    title: `Penicilin the First Antibiotic`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/6.Penicilin the First Antibiotic.png`,
    tag: 'lv6',
  },
  {
    title: `How to survive earthquakes`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/1.How to survive earthquakes.png`,
    tag: 'lv6',
  },
  {
    title: `The Big Bang Theory and the Steady state Universe`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/2.The Big Bang Theory and the Steady state Universe.png`,
    tag: 'lv6',
  },
  {
    title: `The story of Troy`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/3.The story of Troy.png`,
    tag: 'lv6',
  },
  {
    title: `Vincent Van Gogh`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/4.Vincent Van Gogh.png`,
    tag: 'lv6',
  },
  {
    title: `Joan of Arc`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/5.Joan of Arc.png`,
    tag: 'lv6',
  },
  {
    title: `How to use the fire extinguisher`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/6.How to use the fire extinguisher.png`,
    tag: 'lv6',
  },
  {
    title: `Different types of Volcanos`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/1.Different types of Volcanos.png`,
    tag: 'lv6',
  },
  {
    title: `Fun Historical Facts`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/2.Fun Historical Facts.png`,
    tag: 'lv6',
  },
  {
    title: `Man's Ancestor`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/3.Man's Ancestor.png`,
    tag: 'lv6',
  },
  {
    title: `Egypt`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/4.Egypt.png`,
    tag: 'lv6',
  },
  {
    title: `Charles Bridge in prague`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/5.Charles Bridge in prague.png`,
    tag: 'lv6',
  },
  {
    title: `The 6th Extinction`,
    imgUrl: `/images/village/english/contents/story_readers/lv6/6.The 6th Extinction.png`,
    tag: 'lv6',
  },
];

export const villageList: village[] = [
  {
    name: 'storybook',
    villageContent: storybookContents,
    tags: ['spring', 'summer', 'fall', 'winter'],
    group: 'english',
  },
  {
    name: 'story-readers',
    villageContent: storyReadersContents,
    tags: ['lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv6'],
    group: 'english',
  },
];
