import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';

export const NotFoundPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      NotFound 404
    </Template>
  );
};
