import useSWR from 'swr';
import { authApi } from '../libs/apis/authAPi';

export const useAuthRequest = (request, { initialData, ...config } = {}) => {
  return useSWR(request && JSON.stringify(request), () => authApi(request).then((response) => response.data), {
    ...config,
    initialData: initialData && {
      status: 200,
      statusText: 'InitialData',
      headers: {},
      data: initialData,
    },
  });
};
