import { Link } from 'react-router-dom';
import { sitemapInfo } from '../Header/Header';
import { HeaderBtns } from '../HeaderBtns/HeaderBtns';
import './HeaderMobileMenu.css';

type HeaderMobileMenuProps = {
  sitemap: sitemapInfo[];
  isOpenMenu: boolean;
  isSession: boolean;
  onClickLogout(): void;
  onClickMobileMenu(): void;
};
export const HeaderMobileMenu = ({
  sitemap,
  isOpenMenu,
  isSession,
  onClickLogout,
  onClickMobileMenu,
}: HeaderMobileMenuProps) => {
  return (
    <div className='z-50 block lg:hidden'>
      <div className={`mobile-menu-overlay ${isOpenMenu && 'is-mobile-menu-open'}`} onClick={onClickMobileMenu} />
      <div className={`mobile-menu-slide ${isOpenMenu && 'is-mobile-menu-open-slide'}`}>
        <div
          className='h-full box-border overflow-auto text-xl text-white font-bold rounded-r-2xl'
          style={{ background: '#704904' }}
        >
          <div className='flex flex-col px-8 pt-20 pb-8 h-full'>
            <ul>
              {sitemap.map((link, index) => (
                <div className='my-8' key={index}>
                  {!link.type ? (
                    <Link to={link.url || ''}>
                      <li>{link.text}</li>
                    </Link>
                  ) : (
                    <a href={link.url} target={link.type}>
                      {link.text}
                    </a>
                  )}
                </div>
              ))}
            </ul>
            <div className='mt-auto'>
              <HeaderBtns isSession={isSession} onClickLogout={onClickLogout} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
