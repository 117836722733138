import './DownloadBanner.css';

export const DownloadBanner = () => {
  return (
    <div className='DownloadBanner'>
      <div className='h-full px-8 lg:px-0 py-16'>
        <div className='relative text-primary container mx-auto text-left text-5xl lg:text-7xl font-extrabold h-full flex items-end z-10'>
          다운로드
        </div>
        <div className='absolute w-4/5 md:w-2/4 xl:w-auto right-0 xl:right-48 bottom-0'>
          <img className='download-illust' src={'/images/download/download_illust.png'} alt='Download' />
        </div>
      </div>
    </div>
  );
};
