import { useState } from 'react';
import { Link } from 'react-router-dom';

export const MainBanner = () => {
  const [isHover, setIsHover] = useState('');

  const onHoverVillage = (viliageName: string) => {
    if (!viliageName) {
      setIsHover('');
      return;
    }
    setIsHover(viliageName);
  };
  return (
    <div className='relative' style={{ height: '864px' }}>
      <div
        className='max-w-full align-top h-full bg-center bg-no-repeat bg-cover'
        style={{ backgroundImage: `url('/images/main/banner_bg.png')` }}
      />

      <div className='flex-col md:flex-row absolute top-0 w-full flex items-center justify-center h-full py-12 space-x-4'>
        <div className='w-64 md:w-96 self-start transform hover:-translate-y-4 duration-500'>
          <Link to='/village/english'>
            <img src={'/images/main/1_village.png'} alt='English Village' />
          </Link>
        </div>
        <div className='w-64 md:w-96 self-end transform cursor-pointer hover:-translate-y-4 duration-500'>
          {/* <Link to='/village/math'> */}
          <img
            src={isHover !== 'math' ? '/images/main/2_village.png' : '/images/main/2_village_preparing.png'}
            alt='Math Village'
            onMouseOver={() => onHoverVillage('math')}
            onMouseOut={() => onHoverVillage('')}
          />
          {/* </Link> */}
        </div>
        <div className='w-64 md:w-96 self-start transform cursor-pointer hover:-translate-y-4 duration-500'>
          {/* <Link to='/village/science'> */}
          <img
            src={isHover !== 'science' ? '/images/main/3_village.png' : '/images/main/3_village_preparing.png'}
            alt='Science Village'
            onMouseOver={() => onHoverVillage('science')}
            onMouseOut={() => onHoverVillage('')}
          />
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};
