export const Footer = () => {
  return (
    <div className='relative bg-gray-100 text-gray-700'>
      <div className='flex items-center h-60 xl:mx-60'>
        <div className='hidden md:block w-1/5 pl-8'>
          <img src={'/images/base/globepoint_logo.png'} alt='Globepoint Inc' />
        </div>
        <div className='ml-8 mb-4 text-gray-600 font-bold text-base md:w-1/2'>
          <div className='my-4'>
            <a href='https://my.vrware.us/policy/privacy' target='_sub'>
              <span>개인정보처리방침이용약관</span>
            </a>
            <span>│</span>
            <a href='https://my.vrware.us/policy/service' target='_sub'>
              <span>이용약관</span>
            </a>
          </div>

          <div className='my-6 text-xs md:text-sm'>
            <p>(주)글로브포인트 사업자등록번호 : 107-87-72034 대표 조상용</p>
            <p>경기도 고양시 일산동구 태극로 60, 1603호 (장항동, 빛마루)</p>
            <p>031-911-0609 gpsales@globepoint.co.kr</p>
          </div>

          <div className='text-xs md:text-sm'>Copyrights ⓒ Globepoint Inc. All rights reserved.</div>
        </div>
        <div className='hidden md:block md:w-1/3 my-4 ml-auto pl-12'>
          <div>
            <p className='text-gray-600 font-bold text-lg'>고객센터</p>
            <p className='text-gray-600 font-bold text-2xl'>031-911-0609</p>
            <div className='text-sm'>
              <p>평일 10:00~18:00 (점심시간 12:30~13:30)</p>
              <p>국/공휴일 휴무</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
