import { useState } from 'react';
import { Link } from 'react-router-dom';

export const VillageMobileMenu = () => {
  const [isClick, setIsClick] = useState('');

  const onHoverVillage = (viliageName: string) => {
    if (!viliageName) {
      setIsClick('');
      return;
    }
    console.log(viliageName);
    setIsClick(viliageName);
  };

  return (
    <div className='lg:hidden bg-secondary pt-12 w-full' style={{ maxWidth: '1024px' }}>
      <div className='whitespace-nowrap overflow-x-auto'>
        <div className='inline-block align-top'>
          <Link to='/village/english'>
            <img src={'/images/village/base/english_mobile.png'} alt='English Village' className='min-w-full' />
          </Link>
        </div>
        <div className='inline-block align-top'>
          <img
            src={
              isClick !== 'math'
                ? '/images/village/base/math_mobile.png'
                : '/images/village/base/math_mobile_preparing.png'
            }
            alt='Math Village'
            className='min-w-full'
            onMouseOver={() => onHoverVillage('math')}
            onMouseOut={() => onHoverVillage('')}
          />
        </div>
        <div className='inline-block align-top'>
          <img
            src={
              isClick !== 'science'
                ? '/images/village/base/science_mobile.png'
                : '/images/village/base/science_mobile_preparing.png'
            }
            alt='Science Village'
            className='min-w-full'
            onMouseOver={() => onHoverVillage('science')}
            onMouseOut={() => onHoverVillage('')}
          />
        </div>
      </div>
    </div>
  );
};
