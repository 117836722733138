import './MainHowToLearn.css';

export const MainHowToLearn = () => {
  return (
    <div className='relative py-12 lg:py-28 bg-white z-10'>
      <div className='text-center'>
        <div className='text-xl md:text-3xl lg:text-4xl font-bold inline-block w-44 md:w-60 lg:w-72'>
          다양한 학습 방법
          <div className='how-to-learn-shadow w-full -mt-2.5'></div>
        </div>
      </div>
      <div className='my-12'>
        <div className='flex justify-center items-baseline'>
          <div className='px-4 md:px-8 lg:px-14 text-center'>
            <img src={'/images/main/video.png'} alt='video' />
            <p className='text-sm py-4 md:text-xl lg:text-2xl'>동영상</p>
          </div>
          <div className='px-4 md:px-8 lg:px-14 text-center'>
            <img src={'/images/main/eBook.png'} alt='eBook' />
            <p className='text-sm py-4 md:text-xl lg:text-2xl'>e-Book</p>
          </div>
          <div className='px-4 md:px-8 lg:px-14 text-center'>
            <img src={'/images/main/vr.png'} alt='vr' />
            <p className='text-sm py-4 md:text-xl lg:text-2xl'>VR</p>
          </div>
        </div>
      </div>
    </div>
  );
};
