import './ProgramSpec.css';

export const ProgramSpec = () => {
  return (
    <div className='px-8 pt-4 pb-12 lg:py-12'>
      <div>
        <h2 className='spec-headline text-2xl sm:text-3xl lg:text-5xl'>PC 최소 / 권장사양 안내</h2>
        <div className='overflow-x-auto whitespace-nowrap' style={{ maxWidth: '1300px' }}>
          <table className='w-full'>
            <thead className='spec-head'>
              <tr>
                <th className='spec-col-1'>구분</th>
                <th className='spec-col-rest' colSpan={2}>
                  최소사양
                </th>
                <th className='spec-col-rest'>권장사양</th>
              </tr>
            </thead>
            <tbody>
              <tr className='spec-line'>
                <td className='spec-col-1'>프로세서(CPU)</td>
                <td className='spec-col-rest' colSpan={2}>
                  Intel® Core™ i3 또는 AMD Phenom™ X3 8650
                </td>
                <td className='spec-col-rest'>Intel® Core™ i5 또는 AMD Phenom™ Ⅱ X3 또는 그 이상</td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>메모리(RAM)</td>
                <td className='spec-col-rest' colSpan={2}>
                  4 GB RAM
                </td>
                <td className='spec-col-rest'>6 GB RAM</td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>그래픽카드(VGA)</td>
                <td className='spec-col-rest' colSpan={2}>
                  NVIDIA® GeForce® GTX 460, ATI Radeon™ HD 4850, 또는 Intel® HD Graphics 4400
                </td>
                <td className='spec-col-rest'>NVIDIA® GeForce® GTX 660 또는 AMD Radeon™ HD 7950 또는 그 이상</td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>하드디스크(HDD)</td>
                <td className='spec-col-rest' colSpan={3}>
                  30 GB 이상의 하드 드라이브 여유 공간
                </td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>운영체제(OS)</td>
                <td className='spec-col-rest' colSpan={3}>
                  Windows® 7 / Windows® 8 / Windows® 10 64-bit
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='mt-24'>
        <h2 className='spec-headline text-2xl sm:text-3xl lg:text-5xl'>모바일 최소 / 권장사양 안내</h2>
        <div className='overflow-x-auto whitespace-nowrap' style={{ maxWidth: '1300px' }}>
          <table className='w-full'>
            <thead className='spec-head'>
              <tr>
                <th className='spec-col-1'>구분</th>
                <th className='spec-col-rest'>최소사양</th>
                <th className='spec-col-rest'>권장사양</th>
              </tr>
            </thead>
            <tbody>
              <tr className='spec-line'>
                <td className='spec-col-1'>운영체제 버전(OS Version)</td>
                <td className='spec-col-rest'>Android Version 4.4 이상</td>
                <td className='spec-col-rest'>Android Version 8 이상</td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>메모리(RAM)</td>
                <td className='spec-col-rest'>3 GB RAM 이상</td>
                <td className='spec-col-rest'>4 GB RAM 이상</td>
              </tr>
              <tr className='spec-line'>
                <td className='spec-col-1'>저장소 용량(Storage)</td>
                <td className='spec-col-rest'>7 GB 이상의 여유 공간</td>
                <td className='spec-col-rest'>8 GB 이상의 여유 공간</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
