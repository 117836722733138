import axios, { AxiosInstance } from 'axios';

const API_URI = 'https://auth.vrware.us/api';
const LOCAL_API_URI = 'http://localhost:8000/api';

let Axios: AxiosInstance;

switch (process.env.NODE_ENV) {
  case 'production':
    Axios = axios.create({
      baseURL: API_URI,
      timeout: 60000,
      withCredentials: true,
      headers: {
        clientId: 'HCWVKWXKYATPGIBSKUBD',
        clientSecretKey: 'AJ1PI98QFEO7faGaceBG68u9PiAfrpRWyYNlOdfO',
      },
    });
    break;
  case 'development':
    Axios = axios.create({
      baseURL: LOCAL_API_URI,
      timeout: 60000,
      withCredentials: true,
    });
    break;
  default:
    throw new Error("process.env.NODE_ENV must be either 'production' or 'development' to use this module");
}

export { Axios as authApi };
