import './HeaderBtns.css';

type HeaderBtnsProps = {
  isSession: boolean;
  onClickLogout(): void;
};

export const HeaderBtns = ({ isSession, onClickLogout }: HeaderBtnsProps) => {
  return (
    <>
      {isSession ? (
        <div className='flex flex-wrap lg:flex-nowrap lg:justify-center items-center lg:space-x-2'>
          <a href='https://my.vrware.us/mypage'>
            <div className='header-btn m-1 lg:m-0'>마이페이지</div>
          </a>
          <div className='header-btn m-1 lg:m-0' onClick={onClickLogout}>
            로그아웃
          </div>
        </div>
      ) : (
        <div className='flex flex-wrap lg:flex-nowrap lg:justify-center items-center lg:space-x-2'>
          <a href='https://my.vrware.us/login'>
            <div className='header-btn m-1 lg:m-0'>로그인</div>
          </a>
          <a href='https://my.vrware.us/join'>
            <div className='header-btn m-1 lg:m-0'>회원가입</div>
          </a>
        </div>
      )}
    </>
  );
};
