import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const isActivedVillage = (curPath: string, comparePath: string) => {
  if (curPath === comparePath) {
    return '-translate-x-8';
  } else {
    return '-translate-x-20';
  }
};

export const VillageSideBar = () => {
  const [isHover, setIsHover] = useState('');

  const location = useLocation();
  const curPath = location.pathname.split('/')[2];

  const onHoverVillage = (viliageName: string) => {
    if (!viliageName) {
      setIsHover('');
      return;
    }
    setIsHover(viliageName);
  };

  return (
    <div className='hidden lg:block absolute top-0 h-full bg-secondary max-w-xs w-80 float-left'>
      <div>
        <div
          className={`absolute top-10 cursor-pointer w-96 transform duration-300 ${isActivedVillage(
            curPath,
            'english',
          )} hover:-translate-x-8`}
        >
          <Link to='/village/english'>
            <img src={'/images/village/base/english.png'} alt='English Village' />
          </Link>
        </div>
        <div
          className={`absolute top-40 cursor-pointer w-96 transform duration-300 ${isActivedVillage(
            curPath,
            'math',
          )} hover:-translate-x-8`}
        >
          {/* <Link to='/village//math'> */}
          <img
            src={isHover !== 'math' ? '/images/village/base/math.png' : '/images/village/base/math_preparing.png'}
            alt='Math Village'
            onMouseOver={() => onHoverVillage('math')}
            onMouseOut={() => onHoverVillage('')}
          />
          {/* </Link> */}
        </div>
        <div
          className={`absolute top-72 cursor-pointer w-96 transform duration-300 ${isActivedVillage(
            curPath,
            'science',
          )} hover:-translate-x-8`}
        >
          {/* <Link to='/village//science'> */}
          <img
            src={
              isHover !== 'science' ? '/images/village/base/science.png' : '/images/village/base/science_preparing.png'
            }
            alt='Science Village'
            onMouseOver={() => onHoverVillage('science')}
            onMouseOut={() => onHoverVillage('')}
          />
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};
