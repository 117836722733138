import React from 'react';

type TemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

export const Template = ({ header, children, footer }: TemplateProps) => {
  return (
    <div>
      {header}
      <main className='relative h-full'>{children}</main>
      {footer}
    </div>
  );
};
