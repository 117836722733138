import { login, logout } from 'hooks/useSession';
import { useAuthRequest } from 'hooks/useAuthRequest';
import { vrwareApi } from 'libs/apis/vrwareApi';
import { DownloadPage } from 'pages/DownloadPage';
import { NotFoundPage } from 'pages/error/NotFoundPage';
import { MainPage } from 'pages/MainPage';
import { useCallback, useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { EnglishIntroPage } from 'pages/english/EnglishIntroPage';
import { EnglishContentsPage } from 'pages/english/EnglishContentsPage';

const App = () => {
  const { data, error } = useAuthRequest(`/v1.1/sessions/cookies`);
  const localSotrageUser = localStorage.getItem('globe_user');

  const loadMemberFormServer = useCallback(
    async (email) => {
      try {
        const memberResp = await vrwareApi.get('/members?email=' + email);

        if (memberResp.data.status_code === 200) {
          const memberInfo = memberResp.data.data.memberInfo[0];

          //localstorage에 유저 정보가 있는데 로그인한 정보와 이메일이 다르다면 로그아웃
          if (localSotrageUser && JSON.parse(localSotrageUser).email !== memberInfo.email) {
            return logout();
          }

          return login(memberInfo);
        }
      } catch (error) {
        if (error.response.data.status_code >= 400) {
          return logout();
        }
      }
    },
    [localSotrageUser],
  );

  useEffect(() => {
    if (error) {
      return logout();
    }
    if (!data) return;

    const email = data.data.email;
    loadMemberFormServer(email);
  }, [data, error, loadMemberFormServer]);

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route exact path='/download' component={DownloadPage} />
        <Route exact path='/village/english' component={EnglishIntroPage} />
        <Route exact path='/village/english/contents/:contentName' component={EnglishContentsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
