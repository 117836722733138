import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { VillageBackground } from 'components/village/base/VillageBackground/VillageBackground';
import { VillageMobileMenu } from 'components/village/base/VillageMobileMenu/VillageMobileMenu';
import { VillageRight } from 'components/village/base/VillageRight/VillageRight';
import { VillageSideBar } from 'components/village/base/VillageSideBar/VillageSideBar';
import { EnglishIntro } from 'components/village/english/EnglishIntro/EnglishIntro';

export const EnglishIntroPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      <VillageMobileMenu />
      <VillageSideBar />
      <VillageBackground />
      <VillageRight>
        <EnglishIntro />
      </VillageRight>
    </Template>
  );
};
