import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { MainBanner } from 'components/main/MainBanner/MainBanner';
import { MainDesc } from 'components/main/MainDesc/MainDesc';
import { MainHowToLearn } from 'components/main/MainHowToLearn/MainHowToLearn';
import { MainSide } from 'components/main/MainSide/MainSide';

export const MainPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      <MainSide />
      <MainBanner />
      <MainDesc />
      <MainHowToLearn />
    </Template>
  );
};
