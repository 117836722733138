import './MainDesc.css';

export const MainDesc = () => {
  return (
    <div className='py-20 bg-secondary relative'>
      <div>
        <img src={'/images/main/1_1.png'} alt='1_1' className='w-28 md:w-52 lg:w-auto absolute top-0 left-0 lg:z-20' />
        <img
          src={'/images/main/1_2.png'}
          alt='1_2'
          className='w-28 md:w-52 lg:w-auto absolute bottom-0 lg:top-0 right-0'
        />
      </div>
      <div className='relative leading-loose text-2xl mt-12 mb-4 md:my-6 lg:my-14 text-center text-black md:text-2xl lg:text-4xl font-bold keep-all z-20'>
        가상의 <span className='impact leading-10 lg:leading-normal'>3D 캐릭터 선생님</span>과 함께하는{' '}
        <span className='impact leading-10 lg:leading-normal'>비대면 학습 콘텐츠!</span>
      </div>
      <div className='relative text-center text-lg leading-6 md:text-xl lg:text-3xl lg:leading-10'>
        <p>
          유아, 초등 대상 <span className='impact leading-10 lg:leading-normal'>영어 수학 과학 학습</span>을
        </p>
        <p>다양한 방법으로 흥미롭게 즐겨보세요!</p>
      </div>
    </div>
  );
};
