import './ProgramDownload.css';

export const ProgramDownload = () => {
  return (
    <div className='flex flex-col lg:flex-row p-8 lg:p-0 my-6 lg:my-14'>
      <div className='w-full lg:w-1/2 my-2 lg:pr-2'>
        <a href='https://api-storage.cloud.toast.com/v1/AUTH_a3290396084c457fac8d06373bf3c8b8/programs/installer/vrware_bookclub_setup_1.0.0.exe'>
          <button className='program-download-btn bg-primary lg:mr-2 text-xl lg:text-2xl keep-all'>
            BookClub PC 버전 다운로드
          </button>
        </a>
      </div>
      <div className='w-full lg:w-1/2 my-2 lg:pl-2'>
        <a href='https://play.google.com/store/apps/details?id=com.Globepoint.BookClub' target='_sub'>
          <button className='program-download-btn bg-3 text-xl lg:text-2xl keep-all'>
            BookClub 모바일 버전 다운로드
          </button>
        </a>
      </div>
    </div>
  );
};
