import useSession from 'hooks/useSession';
import { authApi } from 'libs/apis/authAPi';
import { Link, useHistory } from 'react-router-dom';
import Headroom from 'react-headroom';
import './Header.css';
import { HeaderMobileMenu } from '../HeaderMobileMenu/HeaderMobileMenu';
import { useState } from 'react';
import { HeaderBtns } from '../HeaderBtns/HeaderBtns';

export type sitemapInfo = {
  url?: string;
  text: string;
  type?: string;
};
const sitemap: sitemapInfo[] = [
  { url: '/village/english', text: '제품소개', type: '' },
  { url: '/download', text: '다운로드', type: '' },
  { url: '', text: '가격 및 결제', type: '' },
  { url: 'https://my.vrware.us/cs', text: '고객센터', type: '_sub' },
];

export const Header = () => {
  const { isSession, logout } = useSession();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const history = useHistory();

  const onClickLogout = async () => {
    try {
      const authInfo = await authApi.delete('/sessions', {
        withCredentials: true,
      });

      if (authInfo.data.status_code === 200) {
        logout();
        history.go(0);
      }
    } catch (error) {
      logout();
      history.go(0);
    }
  };

  const onClickMobileMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <>
      <HeaderMobileMenu
        sitemap={sitemap}
        isOpenMenu={isOpenMenu}
        isSession={isSession}
        onClickLogout={onClickLogout}
        onClickMobileMenu={onClickMobileMenu}
      />
      <Headroom>
        <div className='relative bg-header py-4 px-6 text-xl font-bold'>
          <div className='flex items-center'>
            <div className='w-40 lg:w-auto'>
              <Link to='/'>
                <img src={'/images/base/logo.png'} alt='logo' className='max-w-full align-top' />
              </Link>
            </div>
            <div className='hidden lg:block ml-8 xl:ml-24'>
              <ul className='header-nav lg:space-x-4 xl:space-x-16'>
                {sitemap.map((link, index) =>
                  !link.type ? (
                    <Link to={link.url || ''} key={index}>
                      <li>{link.text}</li>
                    </Link>
                  ) : (
                    <a href={link.url} target={link.type} key={index}>
                      {link.text}
                    </a>
                  ),
                )}
              </ul>
            </div>
            <div className='ml-auto'>
              <div className='hidden lg:block'>
                <HeaderBtns isSession={isSession} onClickLogout={onClickLogout} />
              </div>
              <div className='block lg:hidden'>
                <img src={'/images/base/menu.png'} alt='Menu' onClick={onClickMobileMenu} />
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </>
  );
};
