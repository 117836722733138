import { village, villageList } from 'components/village/base/VillageContentList/villageContents';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { VillageContentItem } from '../VillageContentItem/VillageContentItem';

type VillageContentListProps = {
  contentName: string;
};

const firstTxtToUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const VillageContentList = ({ contentName }: VillageContentListProps) => {
  const [curPage, setCurPage] = useState<number>(1);
  const [villageInfo, setVillageInfo] = useState<village>();
  const [curTag, setCurTag] = useState<string>();
  const [limit, setLimit] = useState(6);
  const filteredList =
    villageInfo?.villageContent.filter((contentInfo) => {
      return contentInfo.tag === curTag;
    }) || [];
  const totalPage = Math.ceil(filteredList?.length / limit);

  const onClickFilter = (category: string) => {
    setCurPage(1);
    setCurTag(category);
  };

  const onClickPage = (page: number) => {
    if (totalPage < page || page === 0) return;
    setCurPage(page);
    // window.scrollTo(0, 0);
  };

  useEffect(() => {
    const villageInfo = villageList.find((village) => {
      return village.name === contentName;
    });
    setVillageInfo(villageInfo);
    setCurTag(villageInfo?.tags[0]);
    setCurPage(1);
  }, [contentName]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1280) {
      setLimit(4);
    } else {
      setLimit(6);
    }
  }, []);

  return (
    <div className='p-8 xl:py-8 xl:pl-36 xl:pr-12'>
      <div className='max-w-7xl mx-auto'>
        <div className='flex flex-wrap justify-center items-center'>
          <div className='cursor-pointer p-1 w-auto lg:w-1/2 xl:w-auto'>
            <Link to='/village/english/contents/storybook'>
              <img
                className='mx-auto'
                src={`/images/village/english/storybook_${contentName === 'storybook' ? 'active' : 'normal'}.png`}
                alt='storybook'
              />
            </Link>
          </div>
          <div className='cursor-pointer p-1 w-auto lg:w-1/2 xl:w-auto'>
            <Link to='/village/english/contents/story-readers'>
              <img
                className='mx-auto'
                src={`/images/village/english/story_readers_${
                  contentName === 'story-readers' ? 'active' : 'normal'
                }.png`}
                alt='story_readers'
              />
            </Link>
          </div>
        </div>
        <div className='px-8 my-4'>
          <div className='flex justify-center items-center text-center'>
            {villageInfo?.tags.map((tag, i) => (
              <div
                className={`w-full p-2 lg:p-4 border-4 hover:border-yellow-500 cursor-pointer ${
                  i === 0 ? 'rounded-l-full' : i === villageInfo?.tags.length - 1 && 'rounded-r-full'
                } ${curTag === tag && 'border-yellow-500'}`}
                onClick={() => onClickFilter(tag)}
                key={i}
              >
                <div className='font-tmon text-xl lg:text-2xl xl:text-4xl' style={{ color: '#492100' }}>
                  {firstTxtToUpperCase(tag)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='relative flex flex-nowrap items-center my-8' style={{ minHeight: '540px' }}>
          <div className='absolute left-0 w-20 cursor-pointer z-10' onClick={() => onClickPage(curPage - 1)}>
            <img src={'/images/village/base/left.png'} alt='left' />
          </div>
          <div className='flex-1 flex flex-wrap justify-center items-center px-8 lg:px-16'>
            {filteredList.slice(Math.ceil((curPage - 1) * limit), curPage * limit).map((contentInfo, index) => (
              <VillageContentItem contentInfo={contentInfo} key={index} />
            ))}
          </div>
          <div className='absolute right-0 w-20 cursor-pointer z-10' onClick={() => onClickPage(curPage + 1)}>
            <img src={'/images/village/base/right.png'} alt='right' />
          </div>
        </div>
        <div className='flex flex-nowrap justify-center items-center space-x-2 mt-6 pb-8'>
          {[...Array(totalPage)].map((_, i) => (
            <img key={i} src={`/images/village/base/page_${i === curPage - 1 ? 'active' : 'normal'}.png`} alt='page' />
          ))}
        </div>
      </div>
    </div>
  );
};
