import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { DownloadBanner } from 'components/download/DownloadBanner/DownloadBanner';
import { ProgramDownload } from 'components/download/ProgramDownload/ProgramDownload';
import { ProgramSpec } from 'components/download/ProgramSpec/ProgramSpec';

export const DownloadPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      <DownloadBanner />
      <div className='container mx-auto'>
        <ProgramDownload />
        <ProgramSpec />
      </div>
    </Template>
  );
};
