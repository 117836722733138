import { villageContent } from 'components/village/base/VillageContentList/villageContents';
import Img from 'react-cool-img';

type VillageContentItemProps = {
  contentInfo: villageContent;
};

const sliceTitle = (title: string) => {
  if (title.length >= 20) {
    return title.substring(0, 20) + '...';
  }
  return title;
};

export const VillageContentItem = ({ contentInfo }: VillageContentItemProps) => {
  return (
    <div className='relative w-full m-2 sm:w-2/4 md:w-1/3 xl:w-1/5 lg:mx-8' title={contentInfo.title}>
      <div className='w-full h-72 lg:h-56'>
        <Img
          className='w-full h-full'
          src={contentInfo.imgUrl}
          alt={contentInfo.title}
          placeholder={'/images/village/base/loading.gif'}
        />
      </div>
      <div className='text-center font-nanum font-extrabold text-2xl h-16'>{sliceTitle(contentInfo.title)}</div>
    </div>
  );
};
