import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { VillageBackground } from 'components/village/base/VillageBackground/VillageBackground';
import { VillageContentList } from 'components/village/base/VillageContentList/VillageContentList';
import { VillageSideBar } from 'components/village/base/VillageSideBar/VillageSideBar';
import { useParams } from 'react-router-dom';
import { VillageRight } from 'components/village/base/VillageRight/VillageRight';
import { Footer } from 'components/base/Footer/Footer';
import { VillageMobileMenu } from 'components/village/base/VillageMobileMenu/VillageMobileMenu';

export const EnglishContentsPage = () => {
  const { contentName }: { contentName: string } = useParams();
  return (
    <Template header={<Header />} footer={<Footer />}>
      <VillageMobileMenu />
      <VillageSideBar />
      <VillageBackground />
      <VillageRight>
        <VillageContentList contentName={contentName} />
      </VillageRight>
    </Template>
  );
};
