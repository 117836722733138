export const MainSide = () => {
  return (
    <div className='fixed top-2/4 right-4 lg:right-12 z-30 w-16 lg:w-auto'>
      <div>
        <a href='https://my.vrware.us'>
          <img src={'/images/main/go-to-vrware.png'} alt='Go to VRWARE' />
        </a>
      </div>
    </div>
  );
};
